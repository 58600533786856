const fotoOferta = [
  {
    id: 1,
    url:
      "https://images.ctfassets.net/w14h13xgb84i/Fa7je0bVS9MZZvSjsqXrg/6dc475198dace639487b8cd7d403a250/bu.jpg",
  },
  {
    id: 2,
    url:
      "https://images.ctfassets.net/w14h13xgb84i/1IHzO0OusToKSVG1VgwdTy/b53ce3e21223073648f461bd333bdd37/bu2.jpg",
  },
  {
    id: 3,
    url:
      "https://images.ctfassets.net/w14h13xgb84i/30zElitjMGM7Xvej2tiBEm/2d76c72fd9023fe114875ff2d72371ba/bu3.jpg",
  },
  {
    id: 4,
    url:
      "https://images.ctfassets.net/w14h13xgb84i/1vZkIvCjbHxSxmbNfIIXv4/59accaf441854e6965a7bc6578c41d70/bu4.1.jpg",
  },
]
export default fotoOferta
